import service from "./config.js";
import link from "./link.js"

const wxApiUrl = link.WxRegisterApi.APIService;
const ApiUrl = link.IOtApiChen.APIService;
const wxSetting = {
  projectCode: "100002", //微信AppId
  wxRegisterToUrl: "" //用户授权后微信回调的地址
};

//获取微信授权
export const gainWxAuthorize = async () => {
  let responseData = await service({
    url: wxApiUrl + "/api/WxRegister/GainWxAuthorize",
    method: "get",
    params: {
      projectCode: wxSetting.projectCode,
      viewsName: wxSetting.wxRegisterToUrl
    }
  });
  return responseData;
};

export const systemLogin = async (name, pwd, openId) => {
  let responseData = await service({
    url: ApiUrl + "/api/UserLogin/SystemUserLogin",
    method: "post",
    data: {
      loginName: name,
      loginPwd: pwd,
      wxOpenId: openId
    }
  });
  return responseData;
};

export const automaticLogin = async (openId) => {
  let responseData = await service({
    url: ApiUrl + "/api/UserLogin/AutomaticLogin",
    method: "get",
    params: {
      openId: openId
    }
  });
  return responseData;
};