export default {
  themeColor: "#FD9232",
  themeColorLight: "#FDAA00",
  color: {
    titleColor: "#2A2A2A",
    subTitleColor: "#949494",
    listColor: "#7F7F7F",
    listFocusColor: "#7F7F7F",
    white: "#fff",
    defocus: "#A0A0A0",
    gray: "#c8c8c8",
    red: "#fa5251",
    green: "#27CD87",
  },
  bgColor: {
    color: "#F4F4F5",
  },
  fontSize: {},
};
