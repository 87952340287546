import service from "./config.js";
import link from "./link.js"

let ApiUrl = link.IOtApiXiao.APIService;

//获取全局id
export const GetSnowflakeId = async () => {
    let responseData = await service({
        url: ApiUrl + "/api/Common/GetSnowflakeId",
        method: "get",
    });
    return responseData;
}
