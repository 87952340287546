import Vue from "vue";
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';
// import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
dayjs.locale('zh-cn');
dayjs.extend(dayjsPluginUTC, { parseToLocal: true });
dayjs.extend(isLeapYear);
dayjs.extend(weekOfYear);
// dayjs.extend(relativeTime);
Vue.prototype.dayjs = dayjs;
require('dayjs/plugin/badMutable')