import {
  gainWxAuthorize,
  automaticLogin
} from "@/api/axios_login.js";
import defineMethods from "@/methods";

// 绑定用户信息
const bind_wechat = async (to, from, next) => {

  let user = defineMethods.getCookie("LoginUserInfo");
  //是否存在缓存
  if (defineMethods.isNullOrEmpty(user)) {

    //账号异常(无账号信息等...)
    next("/SmartClassroom/Login");
    return;
  }
  next("/SmartClassroom/Index");
}


export default bind_wechat;