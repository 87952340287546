let ApiService = {
  "UploadApplicationApI": {
    "ApiName": "文件上传服务PAI-->UploadApplication-->王阳", //API名称
    "Sandbox": "http://localhost:48652/", //测试环境
    "Running": "https://apiupload.huaxinhe.com.cn", //正式环境
    "RuntimeType": "Running", //当前运行的环境   类型1：Sandbox:测试环境,类型2：Running正式环境
    "APIService": "" //API输出地址
  },
  "IOtApiChen": {
    "ApiName": "智能终端--> 陈浩", //API名称
    "Sandbox": "http://localhost:42891", //测试环境
    "Running": "https://apiterminal.huaxinhe.com.cn", //正式环境
    "RuntimeType": "Running", //当前运行的环境   类型1：Sandbox:测试环境,类型2：Running正式环境
    "APIService": "" //API输出地址
  },
  "IOtApiHong": {
    "ApiName": "智能终端--> 洪苑华", //API名称
    "Sandbox": "http://localhost:42891/", //测试环境
    "Running": "https://apiterminal.huaxinhe.com.cn", //正式环境
    "RuntimeType": "Running", //当前运行的环境   类型1：Sandbox:测试环境,类型2：Running正式环境
    "APIService": "" //API输出地址
  },
  "IOtApiXiao": {
    "ApiName": "智能终端--> 肖志龙", //API名称
    "Sandbox": "http://localhost:42891/", //测试环境
    "Running": "https://apiterminal.huaxinhe.com.cn", //正式环境
    "RuntimeType": "Running", //当前运行的环境   类型1：Sandbox:测试环境,类型2：Running正式环境
    "APIService": "" //API输出地址
  },
  "WxRegisterApi": {
    "ApiName": "微信授权-->User-->陈浩", //API名称
    "Sandbox": "http://localhost:36371", //测试环境
    "Running": "https://wxsystem.huaxinhe.com.cn", //正式环境
    "RuntimeType": "Running", //当前运行的环境   类型1：Sandbox:测试环境,类型2：Running正式环境
    "APIService": "" //API输出地址
  },
  "testMock": {
    url: 'https://www.fastmock.site/mock/6cf6b3e8e35c68e4efc426938056d1af/miniprogram'
  }
}
for (const key in ApiService) {
  //默认为正式环境
  ApiService[key].APIService = ApiService[key].Running;
  //如果配置Sandbox
  if (ApiService[key].RuntimeType === "Sandbox") {
    ApiService[key].APIService = ApiService[key].Sandbox;
  }
}

export default ApiService;