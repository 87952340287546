import service from './config.js'
import link from './link.js'

let ApiUrl = link.IOtApiXiao.APIService

//根据终端类型获取终端命令
export const GetTerminalCommandByTerminalType = async terminalType => {
	let responseData = await service({
		url: ApiUrl + '/api/Terminal/GetTerminalCommandByTerminalType',
		method: 'get',
		params: {
			terminalType: terminalType,
		},
	})
	return responseData
}
