<template>
  <div id="app">
    <transition :name="transitionName">
      <vue-page-stack>
        <router-view :key="$route.fullPath"
                     class="router-view-c"> </router-view>
      </vue-page-stack>
    </transition>
  </div>
</template>
<script>
import { GetDepartmentAll } from '@/api/PeopleManagement/people.js'
import { GetTerminalCommandByTerminalType } from '@/api/terminal.js'
export default {
  name: 'app',
  data () {
    return {
      transitionName: 'forward',
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.pauseAnimation === true) {
        this.transitionName = 'clear'
      } else {
        if (to.params['stack-key-dir'] === 'forward') {
          this.transitionName = 'forward'
        } else {
          this.transitionName = 'back'
        }
      }
    },
  },
  methods: {
    localSocket () {
      let that = this
      if ('WebSocket' in window) {
        that.global.ws.push({
          name: 'door',
          info: new WebSocket(`wss://wssdoor.huaxinhe.com.cn/door`),
        })
        that.global.ws.push({
          name: 'terminal',
          info: new WebSocket(`wss://wssdoor.huaxinhe.com.cn/terminal`),
        })
        that.global.setWs()
      } else {
        // 浏览器不支持 WebSocket
        console.log('您的浏览器不支持 WebSocket!')
      }
    },
    //F5刷新页面方法
    listenPage () {
      var that = this
      window.onbeforeunload = function (e) {
        that.$store.state.webSocketInfo.close()
        that.ws.close()
      }
    },
    //熄屏重连
    visibility () {
      let that = this
      document.addEventListener('visibilitychange', () => {
        //页面呼出
        if (!document.hidden) {
          that.global.ws.forEach((item, i) => {
            if (item.info.readyState === 2 || item.info.readyState === 3) {
              that.global.wsReconnection(item.name, item.info.url, i)
            }
          });
        }
      })
    },
    async GetDepartment () {
      let result = await GetDepartmentAll()
      if (result.ResultCode === 200) {
        this.defineMethods.setSessionStorage('Department', JSON.stringify(result.Data))
      }
    },
    async GetTerminalCommand () {
      let result = await GetTerminalCommandByTerminalType(0)
      if (result.ResultCode === 200) {
        this.defineMethods.setSessionStorage(
          'TerminalCommandList',
          JSON.stringify(result.Data)
        )
      }
    },
  },
  created () {
    if (this.global.ws.length == 0) this.localSocket()

    if (this.defineMethods.isNullOrEmpty(this.defineMethods.getSessionStorage('Department'))) {
      this.GetDepartment()
    }
    if (
      this.defineMethods.isNullOrEmpty(
        this.defineMethods.getSessionStorage('TerminalCommandList')
      )
    ) {
      this.GetTerminalCommand()
    }
  },
  mounted () {
    this.visibility()
    this.listenPage()
  },
}
</script>

<style lang="scss">
#app {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background: #f2f6fc;
	font-size: 12px;
}
.router-view-c {
	position: absolute;
	transition: opacity 0.5s, transform 0.5s;
	width: 100%;
	top: 0;
	left: 0;
}

.forward-enter,
.back-leave-active {
	opacity: 0.5;
	transform: translateX(100%);
}

.forward-leave-active,
.back-enter {
	opacity: 0.5;
	transform: translateX(-100%);
}
</style>
