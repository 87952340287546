import wxBinding from './wxBinding_news.js'

const routes = [
	{
		path: '/',
		name: 'Index',
		//redirect: '/SmartClassroom',
		beforeEnter: wxBinding,
	},
	{
		path: '/BindWechat',
		name: 'BindWechat',
		meta: {
			title: '绑定微信',
		},
		component: () => import('@/views/BindWechat.vue'),
	},
	{
		path: '/SmartClassroom/Login',
		name: 'SmartClassroomLogin',
		meta: {
			title: '登录',
			showIcon: false,
		},
		component: () => import('@/views/SmartClassroom/Login.vue'),
	},
	{
		path: '/SmartClassroom',
		name: 'SmartClassroom',
		redirect: '/SmartClassroom/Index',
		component: () => import('@/views/SmartClassroom/Index.vue'),
		children: [
			{
				path: '/SmartClassroom/Index',
				name: 'SmartClassroomIndex',
				meta: {
					title: '首页',
					showIcon: true,
				},
				component: () => import('@/views/SmartClassroom/HomeMenu.vue'),
			},
			{
				path: '/SmartClassroom/Controls',
				name: 'SmartClassroomControls',
				meta: {
					title: '控制台',
					showIcon: true,
				},
				component: () => import('@/views/SmartClassroom/Controls.vue'),
			},
			{
				path: '/SmartClassroom/Equipement',
				name: 'SmartClassroomEquipement',
				meta: {
					title: '智能照明灯',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Equipment/Index.vue'),
			},
			{
				path: '/SmartClassroom/Camera',
				name: 'SmartClassroomCamera',
				meta: {
					title: '摄像机',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Equipment/Camera.vue'),
			},
			{
				path: '/SmartClassroom/AllEquipement',
				name: 'SmartClassroomAllEquipement',
				meta: {
					title: '所有设备',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Equipment/AllEquipment.vue'),
			},
			{
				path: '/SmartClassroom/EquipmentList',
				name: 'SmartClassroomEquipmentList',
				meta: {
					title: '设备列表',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Equipment/EquipmentList.vue'),
			},
			{
				path: '/SmartClassroom/AppointmentList',
				name: 'SmartClassroomAppointmentList',
				meta: {
					title: '预约列表',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Appointment/AppointmentList.vue'),
			},
			{
				path: '/SmartClassroom/AddAppointment',
				name: 'SmartClassroomAddAppointment',
				meta: {
					title: '添加预约',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Appointment/AddAppointment.vue'),
			},
			{
				path: '/SmartClassroom/AlarmDetails',
				name: 'SmartClassroomAlarmDetails',
				meta: {
					title: '故障详情',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Equipment/AlarmDetails.vue'),
			},
			{
				path: '/SmartClassroom/EquipmentAlarm',
				name: 'SmartClassroomEquipmentAlarm',
				meta: {
					title: '设备报警',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Equipment/EquipmentAlarm.vue'),
			},
			{
				path: '/SmartClassroom/Filter',
				name: 'SmartClassroomFilter',
				meta: {
					title: '筛选',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Filter.vue'),
			},
			{
				path: '/SmartClassroom/RoomList',
				name: 'SmartClassroomRoomList',
				meta: {
					title: '房间列表',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/RoomList.vue'),
			},
			{
				path: '/SmartClassroom/StaffManagement',
				name: 'SmartClassStaffManagement',
				meta: {
					title: '员工管理',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Staff/StaffManagement.vue'),
			},
			{
				path: '/SmartClassroom/AddStaff',
				name: 'SmartClassAddStaff',
				meta: {
					title: '添加员工',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Staff/AddStaff.vue'),
			},
			{
				path: '/SmartClassroom/StaffManagementDetails',
				name: 'SmartClassStaffManagementDetails',
				meta: {
					title: '员工管理详情',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Staff/StaffManagementDetails.vue'),
			},
			{
				path: '/SmartClassroom/Hydropower',
				name: 'SmartClassHydropower',
				meta: {
					title: '水电统计',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Hydropower.vue'),
			},
			{
				path: '/SmartClassroom/DetectionIndex',
				name: 'SmartClassDetectionIndex',
				meta: {
					title: '智能检测',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Detection/Index.vue'),
			},
			{
				path: '/SmartClassroom/Scanning',
				name: 'SmartClassDetectionScanning',
				meta: {
					title: '正在扫描',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Detection/Scanning.vue'),
			},
			{
				path: '/SmartClassroom/Result',
				name: 'SmartClassDetectionResult',
				meta: {
					title: '扫描完成',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Detection/Result.vue'),
			},
			{
				path: '/SmartClassroom/Attendance',
				name: 'SmartClassAttendance',
				meta: {
					title: '个人考勤',
					showIcon: false,
				},
				component: () => import('@/views/SmartClassroom/Attendance.vue'),
			},
			{
				path: '/SmartClassroom/Mine',
				name: 'SmartClassMine',
				meta: {
					title: '我的',
					showIcon: true,
				},
				component: () => import('@/views/SmartClassroom/Mine.vue'),
			},
		],
	},
	{
		path: '/FollowUs',
		name: 'FollowUs',
		meta: {
			title: '关注公众号',
		},
		component: () => import('../views/FollowUs.vue'),
	},
	{
		path: '/404',
		name: '404',
		meta: {
			title: '404',
		},
		component: () => import('../views/Error/404.vue'),
	},
	{
		path: '/403',
		name: '403',
		meta: {
			title: '403',
		},
		component: () => import('../views/Error/403.vue'),
	},
	{
		path: '/503',
		name: '503',
		meta: {
			title: '503',
		},
		component: () => import('../views/Error/503.vue'),
		beforeEnter: (to, from, next) => {
			if (!to.query.toUrl) {
				next({
					path: '/503',
					query: {
						toUrl: from.path,
					},
				})
				return
			}
			next()
		},
	},
]

export default routes
