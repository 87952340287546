import service from '../config.js'
import link from '../link.js'

let ApiUrl = link.IOtApiXiao.APIService

//添加或修改人员
export const AddOrUpdate = async data => {
	let responseData = await service({
		url: ApiUrl + '/api/PeopleManagement/InsertOrUpdate',
		method: 'post',
		data: data,
	})
	return responseData
}
//删除人员
export const Del = async id => {
	let responseData = await service({
		url: ApiUrl + '/api/PeopleManagement/DelById',
		method: 'post',
		params: { id: id },
	})
	return responseData
}
//获取人员
export const GetByDepartmentOrName = async (
	departmentId,
	nameOrdepartment,
	pageIndex,
	pageSize
) => {
	let responseData = await service({
		url: ApiUrl + '/api/PeopleManagement/GetByDepartmentOrName',
		method: 'get',
		params: {
			departmentId: departmentId,
			nameOrdepartment: nameOrdepartment,
			pageIndex: pageIndex,
			pageSize: pageSize,
		},
	})
	return responseData
}
//获取所有的部门
export const GetDepartmentAll = async () => {
	let responseData = await service({
		url: ApiUrl + '/api/Terminal/GetDepartmentAll',
		method: 'get',
	})
	return responseData
}
//根据id获取数据
export const GetById = async id => {
	let responseData = await service({
		url: ApiUrl + '/api/PeopleManagement/GetById',
		method: 'get',
		params: {
			id: id,
		},
	})
	return responseData
}
//根据id获取名片
export const GetMineById = async id => {
	let responseData = await service({
		url: ApiUrl + '/api/PeopleManagement/GetMineById',
		method: 'get',
		params: {
			id: id,
		},
	})
	return responseData
}
//根据类型获取终端设备
export const GetTerminalsByType = async type => {
	let responseData = await service({
		url: ApiUrl + '/api/PeopleManagement/GetTerminalsByType',
		method: 'get',
		params: {
			type: type,
		},
	})
	return responseData
}
