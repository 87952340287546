import Vue from "vue";
import Vuex from "vuex";
// 引入自定义方法
import defineMethods from "@/methods";
import {
  GetDepartmentAll
} from "@/api/PeopleManagement/people.js";
import {
  GetTerminalCommandByTerminalType
} from "@/api/terminal.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    webSocketInfo: null,
    token: undefined,
    homeControls: true,
    // 家长注册
    userRegisterInfo: {
      Name: "",
      Phone: null,
      IdCard: null,
      OpenId: null,
    },
    childrens: [
      // {
      //   GardenId:14,
      //   GardenName:"碧海小学",
      //   StudentNo:111000,
      //   Name:"xiaozhilong",
      //   GradeId:1,
      //   ClassId:1,
      //   IdCard:350402199302101196,
      //   DeviceSN:"ba45e3e6-fd41-46d8-a83b-689244a62819",
      //   Relation:"监护人",
      // }
    ],
    gardenInfo: null,
    //部门
    Department: null,
    //终端命令
    TerminalCommandList: null,
  },
  mutations: {
    async addDepartment(state) {
      let result = await GetDepartmentAll();
      if (result.ResultCode === 200) {
        state.Department = result.Data;
      }
    },
    async addTerminalCommand(state) {
      let result = await GetTerminalCommandByTerminalType(0);
      if (result.ResultCode === 200) {
        state.TerminalCommandList = result.Data;
      }
    },
    updateToken(state, token) {
      state.token = token;
    },
    updatePersonalInfo(state, personalInfo) {
      state.personalInfo = personalInfo;
    },
    updateStudent(state, student) {
      state.student = student;
    },
    updateChildren(state, childrens) {
      state.childrens = childrens;
    },
    addChildren(state, children) {
      state.childrens.push(children);
    },
    addGardenInfo(state, gardenInfo) {
      state.gardenInfo = gardenInfo;
    },
    initWebSocket(state) {
      let that = this;
      const wsuri = "wss://wssdoor.huaxinhe.com.cn/terminal";
      let websock = new WebSocket(wsuri);
      websock.onmessage = function (e) {
        console.log("灯窗帘_数据接收", e.data);
      };
      websock.onopen = function () {
        console.log('灯窗帘_已连接...');
      };
      websock.onerror = function (e) {
        console.log('灯窗帘_连接异常...', e);
        that.commit("initWebSocket");
      };
      websock.onclose = function () {
        console.log('灯窗帘_连接已关闭...');
        that.commit("initWebSocket");
      };
      state.webSocketInfo = websock;
    }
  },
  actions: {
    requestDepartment({
      commit,
      state
    }, departmentData) {
      commit("addDepartment", departmentData);
    },
    requestToken({
      commit,
      state
    }, token) {
      commit("updateToken", token);
    },
    requestPersonalInfo({
      commit,
      state
    }, personalInfo) {
      commit("updatePersonalInfo", personalInfo);
    },
    requestStudent({
      commit,
      state
    }, student) {
      commit("updateStudent", student);
    },
    requestChildren({
      commit,
      state
    }, children) {
      commit("addChildren", children);
    },
    requestUpdateChildren({
      commit,
      state
    }, childrens) {
      commit("updateChildren", childrens);
    },
    requestGardenInfo({
      commit,
      state
    }, gardenInfo) {
      commit("addGardenInfo", gardenInfo);
    }
  },
  modules: {},
});